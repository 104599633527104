import { NgModule } from '@angular/core';
import { ActivatedRouteSnapshot, PreloadAllModules, RouterModule, RouterStateSnapshot, Routes } from '@angular/router';
import { NotFoundComponent } from './features/not-found/not-found.component';
import { AuthComponent } from './features/auth/auth.component';

import { AuthGuard, hasCustomClaim, redirectLoggedInTo, redirectUnauthorizedTo } from "@angular/fire/auth-guard";

const adminOnly = () => hasCustomClaim('admin');
const belongsToAccount = (next: any) => hasCustomClaim(`account-${next.params.id}`);
/** store redirect URL in auhtservice before login */
const redirectUnauthorizedToLogin = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  sessionStorage.setItem('redirectUrl', state.url);
  return redirectUnauthorizedTo('login');
};

/** Uses the redirectUrl stored in authservice if available to redirect logged in users, or defaults to '/' */
const redirectLoggedInToPreviousPage = () => {
  return redirectLoggedInTo(sessionStorage.getItem('redirectUrl') || '/');
};

const routes: Routes = [
  { path: '', redirectTo: '/my-vizar', pathMatch: 'full' },
  {
    path: 'login',
    component: AuthComponent,
    canActivate: [AuthGuard],
    data: { authGuardPipe: redirectLoggedInToPreviousPage, hideSideNav: true },
  },
  {
    path: 'my-vizar',
    loadChildren: () =>
      import('./features/dashboard/dashboard.module').then((m) => m.DashboardModule),
      canActivate: [AuthGuard],
      data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'topics',
    loadChildren: () =>
      import('./features/topics/topics.module').then((m) => m.TopicsModule),
      canActivate: [AuthGuard],
      data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'models',
    loadChildren: () =>
      import('./features/models/models.module').then((m) => m.ModelsModule),
      canActivate: [AuthGuard],
      data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'marketing-plan',
    loadChildren: () =>
      import('./features/marketing-plans/marketing-plans.module').then((m) => m.MarketingPlansModule),
      canActivate: [AuthGuard],
      data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  { 
    path: '**', 
    component: NotFoundComponent,
    data: { hideSideNav: true }
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules,
    onSameUrlNavigation: 'reload'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

