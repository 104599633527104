import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, first, map, shareReplay } from 'rxjs';
import { ContentType } from 'src/app/core/models/content-type';
import { environment } from 'src/environments/environment';
import { ColorService } from './color.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class ContentTypeService {

  private http: HttpClient = inject(HttpClient);
  private colorService: ColorService = inject(ColorService);
  private userService: UserService = inject(UserService);

  private cachedPersonalityModels$: Observable<ContentType[]> | undefined;

  // cache: https://medium.com/@AurelienLeloup/cache-http-requests-with-rxjs-for-angular-eb9bee93824d
  // TODO eventually cache invalidator improvement. now refresh will do

  constructor() { }

  public getContentTypes() {

    if (!this.cachedPersonalityModels$) {
      this.userService.getOrganizationId().pipe(first()).subscribe((organizationId: string) => {
        const options = { params: new HttpParams().set('organizationId', organizationId) };
      this.cachedPersonalityModels$ = this.http.get<ContentType[]>(environment.apiUrl + '/content-types', options).pipe(
        first(),
        map(processes => {
          return processes.map(contentType => {
            return <ContentType>{
              contentTypeId: contentType.contentTypeId,
              name: contentType.name,
              color: contentType.color
            }
          });
        }),
        shareReplay(1)
      );
      });
    }
    return this.cachedPersonalityModels$;
  }

/**
 * Maps an array of ContentType objects to a new array of ContentType objects,
 * with the background and color properties updated based on the original color
 * property.
 *
 * @param {ContentType[]} contentTypes - The array of ContentType objects to map.
 * @return {ContentType[]} The new array of ContentType objects with updated background and color properties.
 */
  public colorContentTypes(contentTypes: ContentType[]): ContentType[] {
    return contentTypes.map(contentType => {
      if (!contentType.color) return contentType;
      return {
        ...contentType,
        background: this.colorService.hexToRGB(contentType.color, '0.33'),
        color: this.colorService.shadeColor(contentType.color, -50)
      }
    });
  }
}
