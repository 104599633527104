import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, first, lastValueFrom, map, shareReplay, switchMap } from 'rxjs';
import { Dimension } from 'src/app/core/models/dimension';
import { Personality } from 'src/app/core/models/personality';
import { PersonalityModel } from 'src/app/core/models/personality-model';
import { environment } from 'src/environments/environment';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class PersonalityModelService {

  private http: HttpClient = inject(HttpClient);

  private cachedPersonalityModels$: Observable<PersonalityModel[]> | undefined;
  private cachedPersonalityModelWitChilds$: Observable<{ personalityModel: PersonalityModel }> | undefined;
  private cachedDimensionsWithCategories$: Observable<Dimension[]> | undefined;

  // cache: https://medium.com/@AurelienLeloup/cache-http-requests-with-rxjs-for-angular-eb9bee93824d
  // TODO eventually cache invalidator improvement. now refresh will do

  constructor(
    private userService: UserService
  ) { }

  public getPersonalityModels(): Observable<PersonalityModel[]> {
    if (!this.cachedPersonalityModels$) {
      this.cachedPersonalityModels$ = this.userService.getOrganizationId().pipe(
        first(),
        switchMap((organizationId: string) => {
          const options = { params: new HttpParams().set('organizationId', organizationId) };
          return this.http.get<PersonalityModel[]>(environment.apiUrl + '/personality-models', options).pipe(
            map(processes => {
              return processes.map(personalityModel => {
                return <PersonalityModel>{
                  personalityModelId: personalityModel.personalityModelId,
                  name: personalityModel.name,
                  description: personalityModel.description
                }
              });
            }),
            shareReplay(1)
          );
        })
      );
    }
    return this.cachedPersonalityModels$;
  }

  public async getDefaultPersonalityModel(): Promise<PersonalityModel> {
    return await lastValueFrom(this.getPersonalityModels()!).then((data: any[]) => data[0]);
  }

  public getPersonalityModel(modelId: string, clearCache: boolean = false): Observable<{ personalityModel: PersonalityModel }> {

    if (!this.cachedPersonalityModelWitChilds$ || clearCache) {
      const url = environment.apiUrl + '/personality-models/' + modelId;
      this.cachedPersonalityModelWitChilds$ = this.http.get<{ personalityModel: PersonalityModel }>(url).pipe(
        first(),
        shareReplay(1)
      );
    }
    return this.cachedPersonalityModelWitChilds$;
  }

  public getDimensionsWithCategoriesByPersonalityModel(modelId: string) {

    if (!this.cachedDimensionsWithCategories$) {
      const url = environment.apiUrl + '/personality-models/' + modelId + '/axes';
      return this.http.get<Dimension[]>(url).pipe(
        first(),
        shareReplay(1)
      );
    }
    return this.cachedDimensionsWithCategories$;
  }

  /**
   * Update the dimensions of the object.
   *
   * @param {Dimension[]} dimensions - an array of dimensions to update
   */
  public updateDimensions(personalityModelId: string, dimensions: Dimension[]): Observable<any> {
    return this.http.post(environment.apiUrl + '/personality-models/' + personalityModelId + '/dimensions', { data: dimensions }).pipe(first());
  }

  public getPersonality(personalityId: string, personalityModelId: string): Observable<Personality> {
    const url = environment.apiUrl + '/personality-models/' + personalityModelId + '/personalities/' + personalityId;
    return this.http.get<Personality>(url).pipe(
      first(),
      shareReplay(1),
      map((personality: any) => {
        return <Personality>{
          personalityId: personality.personalityId,
          name: personality.name,
          description: personality.description,
          created: personality.created,
          updated: personality.updated,
          categoryIds: [personality.x_category_id, personality.y_category_id]
        }
      })
    );
  }

  public createPersonality(personalityModelId: string, personality: Personality): Observable<any> {
    return this.http.post(environment.apiUrl + '/personality-models/' + personalityModelId + '/personalities', { data: personality }).pipe(first());
  }

  public updatePersonality(personalityModelId: string, personality: Personality): Observable<any> {
    return this.http.put(environment.apiUrl + '/personality-models/' + personalityModelId + '/personalities/' + personality.personalityId, { data: personality }).pipe(first());
  }

  public deletePersonality(personalityModelId: string, personalityId: string): Observable<any> {
    return this.http.delete(environment.apiUrl + '/personality-models/' + personalityModelId + '/personalities/' + personalityId).pipe(first());
  }

  public getUsedCategories(personalityModelId: string): Observable<{ dimension_id: string, dimension_category_id_a: string, dimension_category_id_b: string }[]> {
    return this.http.get(environment.apiUrl + '/personality-models/' + personalityModelId + '/used-categories').pipe(
      first(),
      map((data: any) => {
        return data
      }
      )
    );
  }

}




