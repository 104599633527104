import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { first, map, Observable, shareReplay } from 'rxjs';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private http: HttpClient = inject(HttpClient);

  private cachedOrganizationId$!: Observable<string>;

  constructor() { }

  updateUser(email: string, input: { displayName: string | null, photoURL: string | null, uid: string }) {
    // response silent
    this.http.put(environment.apiUrl + '/users/email/' + email, { data: input }).pipe(first()).subscribe();
  }

  getUsername(uuid: string) {
    return this.http.get(environment.apiUrl + '/users/' + uuid);
  }

  getOrganizationId(clearCache: boolean = false): Observable<string> {
    if (!this.cachedOrganizationId$ || clearCache) {
      const options = {} //{ params: new HttpParams().set('modelId', personalityModel.personalityModelId) };
      this.cachedOrganizationId$ = this.http.get<any>(environment.apiUrl + '/users/organization', options).pipe(
        first(),
        map(result => result.organization_id),
        shareReplay(1),
      );
    }
    return this.cachedOrganizationId$;
  }
}
